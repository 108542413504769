import React, { Component } from "react";

export default class Footer extends Component {
    handleMouseOver = (e) => {
        e.target.className = "opacity-05";
    };

    handleMouseOut = (e) => {
        e.target.className = "";
    };

    render() {
        return (
            <>
                <footer className="footer">
                    <div className="footer-wrap">
                        <p className="f-logo">
                            <img src="/_img/img_logo_bo.png" alt="Block Odyssey" className="img-normal" />
                        </p>
                        <div className="f-copyright">
                            <span> 사업번호 252-86-01109 </span> <span className="vertical-line"> | </span>
                            <span> 대표이사 김기영 </span> <span className="vertical-line"> | </span>
                            <span> 전화번호 02-2088-6042 </span> <br />
                            <span style={{ width: "210px" }}> 서울시 강남구 학동로 343 9층 </span>
                            <br />
                            <span> Copyright © 2018 BlockOdyssey - All Rights Reserved </span>
                            <br />
                        </div>
                        <ul className="f-sns">
                            <li>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.youtube.com/channel/UCIWzZuOTjzsofxMoTmxXpJA"
                                >
                                    <img
                                        src="/_img/ic_youtube_nor.png"
                                        alt="Youtube"
                                        className="img-normal"
                                        onMouseOver={this.handleMouseOver}
                                        onMouseOut={this.handleMouseOut}
                                    />
                                </a>
                            </li>
                            <li>
                                <a target="_blank" rel="noopener noreferrer" href="https://blog.naver.com/blockodyssey">
                                    <img
                                        src="/_img/ic_blog_nor.png"
                                        alt="Blog"
                                        className="img-normal"
                                        onMouseOver={this.handleMouseOver}
                                        onMouseOut={this.handleMouseOut}
                                    />
                                </a>
                            </li>
                            <li>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.facebook.com/BlockodysseyBlcokchain"
                                >
                                    <img
                                        src="/_img/ic_face_nor.png"
                                        alt="Facebook"
                                        className="img-normal"
                                        onMouseOver={this.handleMouseOver}
                                        onMouseOut={this.handleMouseOut}
                                    />
                                </a>
                            </li>
                            <li>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.instagram.com/blockodyssey"
                                >
                                    <img
                                        src="/_img/ic_instar_nor.png"
                                        alt="Instagram"
                                        className="img-normal"
                                        onMouseOver={this.handleMouseOver}
                                        onMouseOut={this.handleMouseOut}
                                    />
                                </a>
                            </li>
                        </ul>
                    </div>
                </footer>
            </>
        );
    }
}
