import React from "react";
import Router from "../routes/Router";

class App extends React.Component {
    render() {
        return <Router />;
    }
}

export default App;
