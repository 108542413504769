const regExp = (value, type) => {
    var emailCheck = RegExp(/^[A-Za-z0-9_.-]+@[A-Za-z0-9-]+\.[A-Za-z0-9-.]+/);
    if (type === "email") {
        if (!emailCheck.test(value)) {
            return false;
        } else {
            return true;
        }
    }

    var codeCheck = RegExp(/^[A-Za-z0-9]{1,6}$/);
    if (type === "code") {
        if (!codeCheck.test(value)) {
            return false;
        } else {
            return true;
        }
    }

    var nameCheck = RegExp(/^[A-Za-z0-9-_.]{6,20}$/);
    if (type === "urlname") {
        if (!nameCheck.test(value)) {
            return false;
        } else {
            return true;
        }
    }

    // var passwordCheck = RegExp(/^(?=.*\d)(?=.*\w)(?=.*[!@#$%^*()\-=+\\\|\[\]{};:\'",.<>\/?]).{8,20}$/);
    // eslint-disable-next-line
    var passwordCheck = RegExp(/^[A-Za-z0-9!@#$%^*()\-=+\\\|\[\]{};:\'",.<>\/?]{8,20}$/);
    if (type === "password") {
        if (!passwordCheck.test(value)) {
            return false;
        } else {
            return true;
        }
    }
};

module.exports = {
    regExp
};
