// const genSURL = `http://localhost:3000/surl/svc`; // dev
// const genSURL = `http://192.168.2.28:3000/surl/svc`; // network
const genSURL = `https://api.qrcod.kr/surl/svc`;

// const userURL = `http://localhost:3000/surl/user`; // dev
// const userURL = `http://192.168.2.28:3000/surl/user`; // network
const userURL = `https://api.qrcod.kr/surl/user`;

module.exports = {
    genSURL,
    userURL
};
