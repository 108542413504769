import React from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { genSURL, userURL } from "../../api/surlApi";
import { regExp } from "../../utils/common";
import Snackbar from "../layout/Alert";
import Modal from "@material-ui/core/Modal";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CloseIcon from "@material-ui/icons/Close";
import Footer from "../layout/Footer";
import "./Home.css";

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.txtInput = React.createRef();
        this.pwInput = React.createRef();
        this.urlInput = React.createRef();
        this.nameInput = React.createRef();
        this.findPwInput = React.createRef();
        this.codeInput = React.createRef();
        this.newPwInput = React.createRef();
        this.newPwCheckInput = React.createRef();
        this.btnDiv = React.createRef();

        this.state = {
            loading: false,
            options: [{ label: "", option: "" }],
            isLogin: false,
            modalOpen: false,
            modalType: 0,
            userId: "",
            userPw: "",
            findPw: "",
            verificationCode: "",
            userCode: "",
            newPw: "",
            newPwCheck: "",
            emailRmb: true,
            url: "",
            shortUrl: "",
            userUrlName: "",
            urlName: "",
            resultOpen: false,
            copied: false,
            openSnackBar: false
        };
    }

    async componentDidMount() {
        if (localStorage.getItem("idx") !== null && localStorage.getItem("idx") !== undefined) {
            await this.getUserInfo();
        }

        this.setState({
            loading: true,
            userId: localStorage.getItem("user_email")
        });
    }

    handleChange = (e) => {
        let { value, name } = e.target;

        if (name !== "emailRmb") {
            if (name === "userUrlName" && this.state.urlName !== "") {
                this.setState({ [name]: value, urlName: "" });
            } else {
                this.setState({ [name]: value }); //default
            }
        } else if (name === "emailRmb") {
            this.setState({ [name]: !this.state.emailRmb });
        }
    };

    handleSelect = (e) => {
        let { name, value } = e.target;
        let { options } = this.state;

        if (name === "urlName" && value !== "") {
            for (var i = 0; i < options.length; i++) {
                if (options[i].value === value) {
                    this.setState({ [name]: value, userUrlName: "" });
                    return;
                }
            }
        } else {
            this.setState({ [name]: "", userUrlName: "" });
        }
    };

    handleKeyPress = (e) => {
        let { name } = e.target;

        if (e.key === "Enter") {
            if (name === "url") {
                if (this.state.isLogin) {
                    this.memberGenerateUrl();
                } else {
                    this.generateUrl();
                }
            } else if (name === "userId" || name === "userPw") {
                this.requestLogin();
            } else if (name === "findPw") {
                this.requestCode(e);
            } else if (name === "userCode") {
                this.verifyCode(e);
            } else if (name === "newPw" || name === "newPwCheck") {
                this.changePassword(e);
            } else if (name === "userUrlName") {
                this.memberGenerateUrl();
            }
        }
    };

    // 로그인 유저가 생성한 별칭과 별칭의 org_url가져오기
    getUserInfo = async () => {
        await axios
            .get(userURL + "/rndurl", { headers: { token: localStorage.getItem("idx") } })
            .then((response) => {
                let { data, result } = response.data;
                let userInfo = [];
                if (result === "SUCCESS") {
                    for (var i = 0; i < data.length; i++) {
                        var option = { value: "", label: "" };
                        option.value = data[i].rnd_url;
                        option.label = data[i].rnd_url;
                        userInfo.push(option);
                    }
                }
                this.setState({ options: userInfo, isLogin: true });
            })
            .catch((error) => {
                let { result } = error.response.data;
                if (result === "TOKEN INVALID") {
                    localStorage.clear();
                    this.props.history.push("/");
                } else {
                    this.setState({ options: [], isLogin: true });
                    return;
                }
            });
    };

    generateUrl = async () => {
        let { url } = this.state;
        if (!url) {
            alert("URL를 입력해주세요.");
            this.urlInput.current.focus();
            return;
        }

        let body = {
            1: url
        };

        await axios
            .post(genSURL, body, { headers: { "Content-Type": "application/json" } })
            .then((response) => {
                let obj = response.data[0];
                this.setState({ resultOpen: true, shortUrl: obj[1] }, () => {
                    this.copyToClipboard();
                });
            })
            .catch((error) => {
                let { message } = error.response.data;
                if (message === "Invalid Org Url") {
                    alert("유효한 URL를 입력해주세요.");
                    this.urlInput.current.focus();
                    return;
                } else if (message === "Duplicate url") {
                    alert("중복된 URL입니다. 다른 URL로 시도해주세요.");
                    this.urlInput.current.focus();
                    return;
                } else {
                    alert("문제가 발생했습니다. 다시 시도해주세요.");
                    return;
                }
            });
    };

    // 로그인 전용
    memberGenerateUrl = async () => {
        let { url, urlName, userUrlName } = this.state;
        let name = "";
        let type = "";

        if (!url) {
            this.urlInput.current.focus();
            return alert("URL를 입력해주세요.");
        }

        if (!urlName) {
            if (!userUrlName) {
                name = "";
            } else {
                name = userUrlName;
            }
            type = "NEW";
        } else {
            name = urlName;
            type = "UPDATE";
        }

        let body = {
            org_url: url,
            url_name: name,
            type
        };

        await axios
            .post(userURL + `/bo`, body, { headers: { "Content-Type": "application/json", token: localStorage.getItem("idx") } })
            .then((response) => {
                let { data } = response.data;
                this.getUserInfo();
                this.setState({ resultOpen: true, shortUrl: data.shortUrl }, () => {
                    this.copyToClipboard();
                });
            })
            .catch((error) => {
                let { message, result } = error.response.data;
                if (result === "INVALID URL") {
                    alert(message);
                    this.urlInput.current.focus();
                    return;
                } else if (result === "TOKEN INVALID") {
                    alert(message);
                    localStorage.clear();
                    this.props.history.push("/");
                    window.location.reload();
                } else if (result === "TOKEN REQUIRED") {
                    alert(message);
                    localStorage.clear();
                    this.props.history.push("/");
                    window.location.reload();
                } else if (result === "NO URL") {
                    alert(message);
                    this.urlInput.current.focus();
                    return;
                } else if (result === "NOT VALID EXPRESSION") {
                    alert(message);
                    this.nameInput.current.focus();
                    return;
                } else {
                    alert(message);
                    return;
                }
            });
    };

    // 자동으로 클립보드에 복사하기
    copyToClipboard = () => {
        let { shortUrl } = this.state;
        var txtarea = document.createElement("textarea");
        document.body.appendChild(txtarea);
        txtarea.innerText = shortUrl;
        txtarea.select();
        txtarea.setSelectionRange(0, 9999);

        var checkSuccess;
        var checkSupport = document.queryCommandSupported("copy");
        if (checkSupport) {
            try {
                checkSuccess = document.execCommand("copy");
            } catch (error) {
                checkSuccess = false;
            }
            if (!checkSuccess) {
                this.handleSnackBar(true);
            }
        } else {
            this.handleSnackBar(true);
        }
        document.body.removeChild(txtarea);
        return;
    };

    handleSnackBar = (value) => {
        this.setState({ openSnackBar: value });
    };

    handleOpen = () => {
        let { modalType } = this.state;
        const cookies = new Cookies(); // 이메일 저장 체크
        var userId, emailRmb;
        if (modalType === 0) {
            if (cookies.get("email") !== null && cookies.get("email") !== undefined) {
                userId = cookies.get("email");
                emailRmb = cookies.get("emailCheck") === "true";
            } else {
                userId = "";
                emailRmb = false;
            }
            this.setState({ modalOpen: true, userId, emailRmb });
        } else {
            this.setState({ modalOpen: true });
        }
    };

    handleClose = () => {
        this.setState({ modalOpen: false, modalType: 0 });
    };

    openFindPassword = () => {
        this.handleClose();
        this.setState({ modalType: 1 }, () => {
            this.handleOpen();
        });
    };

    openReplacePw = () => {
        this.setState({ modalType: 3 }, () => {
            this.handleOpen();
        });
    };

    clickClose = () => {
        this.setState({ modalOpen: false, modalType: 0, userPw: "", findPw: "", userCode: "", verificationCode: "", newPw: "", newPwCheck: "" });
    };

    requestLogin = async () => {
        let { userId, userPw, emailRmb } = this.state;

        if (!userId) {
            this.txtInput.current.focus();
            return alert("아이디(이메일)을 입력해주세요.");
        }

        if (!userPw) {
            this.pwInput.current.focus();
            return alert("비밀번호를 입력해주세요.");
        }

        if (!regExp(userId, "email")) {
            this.txtInput.current.focus();
            return alert("아이디(이메일) 형식이 아닙니다.");
        }

        let body = {
            user_email: userId,
            user_pw: userPw
        };

        await axios
            .post(userURL + `/login`, body, { headers: { "Content-Type": "application/json" } })
            .then((response) => {
                let { result, data } = response.data;
                if (result === "SUCCESS") {
                    const cookies = new Cookies(); // 쿠키 - 이메일 저장
                    if (emailRmb) {
                        let expireDate = new Date();
                        expireDate.setTime(expireDate.getTime() + 1000 * 60 * 60 * 24 * 14); // 14일
                        cookies.set("email", userId, { path: "/", expires: expireDate });
                        cookies.set("emailCheck", emailRmb, { path: "/", expires: expireDate });
                    } else {
                        cookies.remove("email");
                        cookies.remove("emailCheck");
                    }

                    // 로그인 세션
                    localStorage.setItem("idx", data.token);
                    localStorage.setItem("user_email", data.user_email);
                    this.props.history.push("/", { modalOpen: false, isLogin: true });
                    window.location.reload();
                }
            })
            .catch((error) => {
                let { result, message } = error.response.data;
                if (result === "EMPTY" || result === "NO EMAIL" || result === "NOT VALID EXPRESSION") {
                    alert(message);
                    this.txtInput.current.focus();
                    return;
                } else if (result === "INVALID" || result === "NO PASSWORD") {
                    alert(message);
                    this.pwInput.current.focus();
                    return;
                } else {
                    alert(message);
                    return;
                }
            });
    };

    requestCode = async () => {
        let { findPw } = this.state;

        if (!findPw) {
            this.findPwInput.current.focus();
            return alert("아이디(이메일)을 입력해주세요.");
        }

        if (!regExp(findPw, "email")) {
            this.findPwInput.current.focus();
            return alert("아이디(이메일) 형식이 아닙니다.");
        }

        let body = {
            user_email: findPw
        };

        // 해당 메일로 인증문자 보내기
        await axios
            .post(userURL + `/email`, body, { headers: { "Content-Type": "application/json" } })
            .then((response) => {
                let { data, result } = response.data;
                if (result === "SUCCESS") {
                    this.handleClose();
                    this.setState({ userId: "", userPw: "", verificationCode: data.code, modalType: 2 }, () => {
                        this.handleOpen();
                    });
                }
            })
            .catch((error) => {
                let { result, message } = error.response.data;
                if (result === "EMPTY" || result === "NO EMAIL" || result === "NOT VALID EXPRESSION") {
                    alert(message);
                    this.findPwInput.current.focus();
                    return;
                } else {
                    alert("문제가 발생했습니다. 다시 시도해 주세요.");
                    return;
                }
            });
    };

    verifyCode = async () => {
        let { userCode, verificationCode } = this.state;

        if (!userCode) {
            this.codeInput.current.focus();
            return alert("인증번호를 입력해주세요.");
        }

        if (!regExp(userCode, "code")) {
            this.codeInput.current.focus();
            return alert("인증번호가 다릅니다. 재확인해주세요.");
        }

        let body = {
            user_code: userCode,
            auth_code: verificationCode
        };

        // 인증번호 확인
        await axios
            .post(userURL + `/authcode`, body, { headers: { "Content-Type": "application/json" } })
            .then((response) => {
                let { result } = response.data;
                if (result === "SUCCESS") {
                    this.handleClose();
                    this.setState({ modalType: 3 }, () => {
                        this.handleOpen();
                    });
                }
            })
            .catch((error) => {
                let { result, message } = error.response.data;
                if (result === "INVALID CODE" || result === "NO CODE") {
                    alert(message);
                    this.codeInput.current.focus();
                    return;
                } else {
                    alert("문제가 발생했습니다. 다시 시도해주세요.");
                    console.log(error);
                    return;
                }
            });
    };

    changePassword = () => {
        let { userId, newPwCheck, newPw, findPw } = this.state;

        if (!newPw) {
            this.newPwInput.current.focus();
            return alert("새로운 비밀번호를 입력해주세요.");
        }

        if (!newPwCheck) {
            this.newPwCheckInput.current.focus();
            return alert("새로운 비밀번호 확인을 입력해주세요.");
        }

        if (newPw !== newPwCheck) {
            this.newPwCheck.current.focus();
            return alert("비밀번호가 다릅니다. 확인해주세요.");
        }

        let body = {
            user_email: userId,
            new_pw: newPw,
            pw_check: newPwCheck
        };

        if (!userId) {
            body.user_email = findPw;
        }

        // 비밀번호 변경
        axios
            .put(userURL + "/password", body, { headers: { "Content-Type": "application/json", token: localStorage.getItem("idx") } })
            .then((response) => {
                let { result, message } = response.data;
                if (result === "SUCCESS") {
                    alert(message);
                    this.setState({ newPw: "", newPwCheck: "", userCode: "", verificationCode: "", modalOpen: false, modalType: 0 });
                } else {
                    alert(message);
                    return;
                }
            })
            .catch((error) => {
                let { result, message } = error.response.data;
                if (result === "NO PASSWORD") {
                    alert(message);
                    this.newPwInput.current.focus();
                    return;
                } else if (result === "NO PASSWORD CHECK") {
                    alert(message);
                    this.newPwCheckInput.current.focus();
                    return;
                } else if (result === "NOT MATCH") {
                    alert(message);
                    return;
                } else {
                    alert("문제가 발생했습니다. 잠시 후 다시 시도해주세요.");
                    return;
                }
            });
    };

    logout = () => {
        alert("로그아웃되었습니다.");
        localStorage.clear();
        this.props.history.push("/");
        window.location.reload();
    };

    render() {
        let { loading, options, modalOpen, isLogin, userId, userPw, url, emailRmb, shortUrl, urlName, userUrlName, resultOpen, modalType, findPw, userCode, newPw, newPwCheck } = this.state;

        return (
            <>
                <header className="header">
                    <div className="header-wrap">
                        <h1>
                            <a href="/" className="logo">
                                <span className="hidden-logo">QRCOD.KR</span>
                            </a>
                        </h1>
                        {loading && isLogin ? (
                            <div className="container" onMouseOver={() => (this.btnDiv.current.className = "btn-wrap show")} onMouseOut={() => (this.btnDiv.current.className = "btn-wrap")}>
                                <p>{userId}</p>
                                <div className="btn-wrap" ref={this.btnDiv}>
                                    <ul>
                                        <li>
                                            <button type="button" onClick={this.openReplacePw}>
                                                비밀번호 변경
                                            </button>
                                        </li>
                                        <li>
                                            <button type="button" onClick={this.logout}>
                                                로그아웃
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        ) : (
                            <button className="open-btn" type="button" onClick={this.handleOpen}>
                                로그인
                            </button>
                        )}
                    </div>
                </header>
                <section className="contents">
                    <div className={isLogin ? "url-section login" : "url-section"}>
                        <h2>단축 URL 서비스</h2>
                        <form className={isLogin ? "url-form login" : "url-form"} onSubmit={(e) => e.preventDefault()}>
                            {isLogin ? (
                                <div className="inputs-wrap login">
                                    <input
                                        className="url-txt"
                                        placeholder="긴 URL를 입력하세요."
                                        name="url"
                                        value={url || ""}
                                        type="text"
                                        ref={this.urlInput}
                                        onChange={this.handleChange}
                                        onKeyPress={this.handleKeyPress}
                                    />
                                    <div className="url-name-wrapper">
                                        <Select
                                            className="url-select"
                                            name="urlName"
                                            value={urlName}
                                            onClick={this.test}
                                            onChange={this.handleSelect}
                                            displayEmpty
                                            inputProps={{ "aria-label": "Without label" }}
                                        >
                                            <MenuItem value="">
                                                <em>직접입력</em>
                                            </MenuItem>
                                            {loading &&
                                                options !== undefined &&
                                                options.map((opt, idx) => (
                                                    <MenuItem key={idx} value={opt.value}>
                                                        <em>{opt.label}</em>
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                        <input
                                            className="url-name"
                                            name="userUrlName"
                                            value={userUrlName}
                                            type="text"
                                            ref={this.nameInput}
                                            onChange={this.handleChange}
                                            onKeyPress={this.handleKeyPress}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className="inputs-wrap">
                                    <input
                                        className="url-txt"
                                        placeholder="긴 URL를 입력하세요."
                                        type="text"
                                        name="url"
                                        value={url}
                                        ref={this.urlInput}
                                        onChange={this.handleChange}
                                        onKeyPress={this.handleKeyPress}
                                    />
                                </div>
                            )}
                            {isLogin ? (
                                <input className="large" type="button" value="URL 생성" onClick={this.memberGenerateUrl} />
                            ) : (
                                <input type="button" value="URL 생성" onClick={this.generateUrl} />
                            )}
                        </form>
                        {resultOpen ? (
                            <>
                                <div className={isLogin ? "url-result login" : "url-result"}>
                                    <a target="_blank" rel="noopener noreferrer" href={shortUrl}>
                                        <span>{shortUrl}</span>
                                    </a>
                                    <CopyToClipboard text={shortUrl} onCopy={() => this.setState({ copied: true })}>
                                        <button className="cp-btn">복사</button>
                                    </CopyToClipboard>
                                </div>
                                {/* <div className="ad-section"></div> */}
                            </>
                        ) : (
                            ""
                            // <div className="ad-section"></div>
                        )}
                    </div>
                </section>
                <Footer />
                <Modal open={modalOpen}>
                    <div className="lgn-modal">
                        <div className="lgn-form">
                            <CloseIcon className="close-icon" onClick={this.clickClose} style={{ position: "absolute", top: "39px", right: "40px", cursor: "pointer" }}></CloseIcon>
                            {modalType === 0 ? (
                                <>
                                    <h2>블록오디세이 통합 로그인</h2>
                                    <input
                                        type="text"
                                        placeholder="아이디(이메일)을 입력하세요"
                                        name="userId"
                                        value={userId}
                                        ref={this.txtInput}
                                        onChange={this.handleChange}
                                        onKeyPress={this.handleKeyPress}
                                    />
                                    <input
                                        type="password"
                                        placeholder="비밀번호를 입력하세요"
                                        name="userPw"
                                        value={userPw}
                                        ref={this.pwInput}
                                        onChange={this.handleChange}
                                        onKeyPress={this.handleKeyPress}
                                    />
                                    <div className="lgn-wrapper">
                                        <label htmlFor="email_rmb">
                                            <Checkbox id="email_rmb" name="emailRmb" checked={emailRmb} value={emailRmb} onChange={this.handleChange} inputProps={{ "aria-label": "checkbox" }} />
                                            <span>이메일 저장</span>
                                        </label>
                                        <button className="find-btn" type="button" name="findPassword" onClick={this.openFindPassword}>
                                            비밀번호 찾기
                                        </button>
                                    </div>
                                    <input type="button" value="로그인" onClick={this.requestLogin} />
                                </>
                            ) : modalType === 1 ? (
                                <>
                                    <h2>비밀번호 찾기</h2>
                                    <input
                                        style={{ marginBottom: "70px" }}
                                        type="text"
                                        placeholder="아이디(이메일)을 입력하세요."
                                        name="findPw"
                                        value={findPw}
                                        ref={this.findPwInput}
                                        onChange={this.handleChange}
                                        onKeyPress={this.handleKeyPress}
                                    />
                                    <input type="button" value="인증번호 발송" name="verifyCode" onClick={this.requestCode} />
                                </>
                            ) : modalType === 2 ? (
                                <>
                                    <h2>비밀번호 찾기</h2>
                                    <input
                                        style={{ marginBottom: "70px" }}
                                        type="text"
                                        placeholder="인증번호를 입력하세요."
                                        name="userCode"
                                        value={userCode}
                                        maxLength="6"
                                        ref={this.codeInput}
                                        onChange={this.handleChange}
                                        onKeyPress={this.handleKeyPress}
                                    />
                                    <input type="button" value="인증번호 확인" name="newPassword" onClick={this.verifyCode} />
                                </>
                            ) : (
                                <>
                                    <h2>비밀번호 변경</h2>
                                    <input
                                        type="password"
                                        placeholder="새로운 비밀번호 입력"
                                        name="newPw"
                                        value={newPw}
                                        maxLength="20"
                                        ref={this.newPwInput}
                                        onChange={this.handleChange}
                                        onKeyPress={this.handleKeyPress}
                                    />
                                    <input
                                        type="password"
                                        style={{ marginBottom: "30px" }}
                                        placeholder="새로운 비밀번호 확인"
                                        name="newPwCheck"
                                        maxLength="20"
                                        value={newPwCheck}
                                        ref={this.newPwCheckInput}
                                        onChange={this.handleChange}
                                        onKeyPress={this.handleKeyPress}
                                    />
                                    <input type="button" value="비밀번호 변경" onClick={this.changePassword} />
                                </>
                            )}
                        </div>
                    </div>
                </Modal>
                <Snackbar open={this.state.openSnackBar} setOpen={this.handleSnackBar} />
            </>
        );
    }
}

export default Home;
