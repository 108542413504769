import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function CustomizedSnackbars({ open, setOpen, type }) {
  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <div>
      {type === "redirectSiv" ? (
        <Snackbar
          open={open}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="error">
            본 서비스는 종료되었습니다. 정품 인증에 관한 자세한 내용은 브랜드
            공식 판매처에서 확인해주세요.
            <br />
            <br />
            此项服务已结束。有关产品认证的其他信息，请咨询购买处。
            <br />
            <br />
            This service has been discontinued. Please check the authrized brand
            retailer for additional information regarding product
            authentication.
          </Alert>
        </Snackbar>
      ) : (
        <Snackbar
          open={open}
          autoHideDuration={5000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          {type === "redirect" ? (
            <Alert onClose={handleClose} severity="error">
              요청하신 페이지를 찾을 수 없습니다. <br></br>
              입력하신 페이지의 주소가 정확한지 다시 한 번 확인해 주시기
              바랍니다. <br></br>
              이전화면으로 되돌아갑니다.
            </Alert>
          ) : (
            <Alert onClose={handleClose} severity="info">
              복사 버튼을 사용해서 URL을 복사해주세요.
            </Alert>
          )}
        </Snackbar>
      )}
    </div>
  );
}
