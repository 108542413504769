import React from "react";
import axios from "axios";
import Snackbar from "../layout/Alert";
import "./Redirect.css";

class RedirectURL extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openSnackBar: false,
      redirectType: "redirect",
      url: this.props.location.pathname.split("/")[1],
    };
  }

  async componentDidMount() {
    this.generateUrl();
  }

  handleSnackBar = (value, redirectType) => {
    this.setState({ openSnackBar: value, redirectType: redirectType });
  };

  generateUrl = async () => {
    const urlParameter = this.props.location.pathname;
    if (urlParameter) {
      const splitedUrlParameter = String(urlParameter).split("/");

      if (splitedUrlParameter.includes("siv")) {
        this.handleSnackBar(true, "redirectSiv");
      } else {
        await axios
          .get("https://api.qrcod.kr" + urlParameter, {
            headers: { "Content-Type": "application/json" },
          })
          .then((response) => {
            let url = response.data.url;
            window.location = url;
          })
          .catch((error) => {
            this.handleSnackBar(true, "redirect");
            setTimeout(() => {
              this.props.history.replace("/");
            }, 5000);
          });
      }
    }
  };
  render() {
    return (
      <>
        <div
          className={
            this.state.url === "cbt" || this.state.url === "mot"
              ? "gov-redirect-wrap"
              : "redirect-wrap"
          }
        >
          {this.state.url === "cbt" || this.state.url === "mot" ? (
            <p>Verifying the authenticity of product</p>
          ) : (
            <p>페이지 이동중입니다.</p>
          )}
        </div>
        <Snackbar
          type={this.state.redirectType}
          open={this.state.openSnackBar}
          setOpen={this.handleSnackBar}
        />
      </>
    );
  }
}

export default RedirectURL;
