import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Home from "../pages/home/Home";
import RedirectURL from "../pages/redirect/RedirectURL";

class Router extends React.Component {
    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route path="/" exact component={Home}></Route>
                    <Route path="/:projectName/:rndUrl" component={RedirectURL}></Route>
                    <Redirect from="*" to="/"></Redirect>
                </Switch>
            </BrowserRouter>
        );
    }
}

export default Router;
